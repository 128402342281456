/* eslint-disable react/prefer-stateless-function */
/* eslint-disable import/no-unresolved, import/extensions, import/no-extraneous-dependencies */
import React from "react";
import {
  FacebookShareCount,
  FacebookShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  EmailShareButton,
  ViberShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  TelegramIcon,
  WhatsappIcon,
  EmailIcon,
  ViberIcon,
} from "react-share";

import "./ShareButton.css";
import { useTranslation } from "react-i18next";

function ShareButton(props) {
  const { t } = useTranslation();
  const shareUrl = props.home?`https://www.talentmakes.com/?share=${props.shareId}`:props.product?`https://www.talentmakes.com/product/${props.productId}/?share=${props.shareId}`: `https://www.talentmakes.com/user/${props.userId}/?share=${props.shareId}`;
  const title = t("shareBtn.msg");

  return (
    <div className="ShareButton__container">
      <div className="ShareButton__some-network">
        <FacebookShareButton
          url={shareUrl}
          quote={title}
          className="ShareButton__some-network__share-button"
        >
          <FacebookIcon size={32} round />
        </FacebookShareButton>

        <div>
          <FacebookShareCount
            url={shareUrl}
            className="ShareButton__some-network__share-count"
          >
            {(count) => count}
          </FacebookShareCount>
        </div>
      </div>

      <div className="ShareButton__some-network">
        <FacebookMessengerShareButton
          url={shareUrl}
          appId="521270401588372"
          className="ShareButton__some-network__share-button"
        >
          <FacebookMessengerIcon size={32} round />
        </FacebookMessengerShareButton>
      </div>

      <div className="ShareButton__some-network">
        <TwitterShareButton
          url={shareUrl}
          title={title}
          className="ShareButton__some-network__share-button"
        >
          <TwitterIcon size={32} round />
        </TwitterShareButton>

        <div className="ShareButton__some-network__share-count">&nbsp;</div>
      </div>

      <div className="ShareButton__some-network">
        <TelegramShareButton
          url={shareUrl}
          title={title}
          className="ShareButton__some-network__share-button"
        >
          <TelegramIcon size={32} round />
        </TelegramShareButton>

        <div className="ShareButton__some-network__share-count">&nbsp;</div>
      </div>

      <div className="ShareButton__some-network">
        <WhatsappShareButton
          url={shareUrl}
          title={title}
          separator=":: "
          className="ShareButton__some-network__share-button"
        >
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>

        <div className="ShareButton__some-network__share-count">&nbsp;</div>
      </div>

      <div className="ShareButton__some-network">
        <LinkedinShareButton
          url={shareUrl}
          className="ShareButton__some-network__share-button"
        >
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
      </div>

      <div className="ShareButton__some-network">
        <EmailShareButton
          url={shareUrl}
          subject={title}
          body="body"
          className="ShareButton__some-network__share-button"
        >
          <EmailIcon size={32} round />
        </EmailShareButton>
      </div>
      <div className="ShareButton__some-network">
        <ViberShareButton
          url={shareUrl}
          title={title}
          className="ShareButton__some-network__share-button"
        >
          <ViberIcon size={32} round />
        </ViberShareButton>
      </div>
    </div>
  );
}

export default ShareButton;
