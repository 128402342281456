import {
  LOGIN_USER,
  REGISTER_USER,
  AUTH_USER,
  LOGOUT_USER,
  ADD_TO_CART_USER,
  GET_CART_ITEM_USER,
  REMOVE_CART_ITEM_USER,
  ON_SUCCESS_BUY_USER,
  ADD_TO_ORDER_USER,
  ON_ORDER_SUCCESS,
  GET_ORDERS_ITEMS,
  REQUEST_DELIVERY,
  ON_DELIVERY_ORDER_SUCCESS,
  ON_ACCEPT_DELIVERY_SUCCESS,
  ON_SEND_DELIVERY_FEEDBACK,
  APPLY_DISCOUNT,
  ADD_PRODUCER_PAYMENT_INFO,
} from "../_actions/types";

// const initialState={
//   userData: {
//     isAuth: false,
//     error: true
//   }
// }

export default function (state = {}, action) {
  switch (action.type) {
    case REGISTER_USER:
      return { ...state, register: action.payload };
    case LOGIN_USER:
      return { ...state, loginSucces: action.payload };
    case AUTH_USER:
      return { ...state, userData: action.payload };
    case LOGOUT_USER:
      return { ...state };
    case ADD_TO_CART_USER:
      return {
        ...state,
        userData: {
          ...state.userData,
          cart: action.payload,
        },
      };

    case ADD_TO_ORDER_USER:
      return {
        ...state,
        userData: {
          ...state.userData,
          orders: action.payload,
        },
      };
    case GET_CART_ITEM_USER:
      return { ...state, cartDetail: action.payload };
    case REMOVE_CART_ITEM_USER:
      return {
        ...state,
        cartDetail: action.payload.cartDetail,
        userData: {
          ...state.userData,
          cart: action.payload.cart,
        },
      };

    case ON_SUCCESS_BUY_USER:
      return {
        ...state,
        userData: {
          ...state.userData,
          cart: action.payload.cart,
        },
        cartDetail: action.payload.cartDetail,
      };

    case ON_ORDER_SUCCESS:
      return {
        ...state,
        userData: {
          ...state.userData,
          cart: action.payload.cart,
        },
        cartDetail: action.payload.cartDetail,
      };

    case GET_ORDERS_ITEMS:
      return { ...state, orders: action.payload };
    case REQUEST_DELIVERY:
      return {
        ...state,
        userData: {
          ...state.userData,
          delivery: action.payload,
        },
      };

    case ON_DELIVERY_ORDER_SUCCESS:
      return {
        ...state,
        userData: {
          ...state.userData,
          orders: action.payload.orders,
        },
      };

    case ON_ACCEPT_DELIVERY_SUCCESS:
      return {
        ...state,
        userData: {
          ...state.userData,
          notifications: action.payload.notifications,
        },
      };

    case ON_SEND_DELIVERY_FEEDBACK:
      return {
        ...state,
        userData: {
          ...state.userData,
          deliveryDates: action.payload.deliveryDates,
        },
      };

    case APPLY_DISCOUNT:
      return {
        ...state,
        userData: {
          ...state.userData, 
         discountedProduct: action.payload,
        }
      };
    case ADD_PRODUCER_PAYMENT_INFO:
      return {
        ...state,
        userData: {
          ...state.userData,
          producerPaymentInfo: action.payload,
        },
      };

    default:
      return state;
  }
}
