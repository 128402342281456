import React from "react";
import { useTranslation } from "react-i18next";

const AppDemo = () => {
  const { t } = useTranslation();
  const myVideo1 =
    "https://res.cloudinary.com/dhf7tdtdc/video/upload/v1720781926/quesjh8ijbu9awrhkcq9.mp4";
  const myVideo2 =
    "https://res.cloudinary.com/dhf7tdtdc/video/upload/v1720781923/mimcbpsaezzkzrii1lu4.mp4";
  return (
    <div>
      <div style={{ marginTop: "50px", textAlign: "center" }}>
        <h1>{t("howItWorks.appDemo")}</h1>
        <h3>{t("howItWorks.appDemoDescription")}</h3>
        <video width="300" controls>
          <source src={myVideo1} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div style={{ marginTop: "50px", textAlign: "center" }}>
        <h1>{t("howItWorks.howToDownload")}</h1>
        <h3>{t("howItWorks.howToDownloadDescription")}</h3>
        <video width="300" controls>
          <source src={myVideo2} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default AppDemo;
