import React, { useState, useEffect } from "react";
import Tour from "reactour";
import { Modal, Icon } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const TourGuide = ({ steps }) => {
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const user = useSelector((state) => state.user);
  const { t } = useTranslation();
  //check if the user has seen the tour before
  useEffect(() => {
    //if the user is logged in and has not seen the tour before
    //show the tour guide
    if (user.userData && user.userData.isAuth) {
      const tourGuide = localStorage.getItem("tourGuide");
      if (!tourGuide) {
        setShowModal(true);
        localStorage.setItem("tourGuide", "true");
      }
    }
  }, [user.userData]);

  const startTour = () => {
    setIsTourOpen(true);
    setShowModal(false);
  };

  const closeTour = () => {
    setIsTourOpen(false);
  };

  return (
    <div>
      <Tour steps={steps} isOpen={isTourOpen} onRequestClose={closeTour} />
      <Modal
        centered
        visible={showModal}
        onOk={() => startTour()}
        onCancel={() => setShowModal(false)}
        okText={t("tourPrompt.startTour")}
        cancelText={t("tourPrompt.skipTour")}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <p>{t("tourPrompt.welcomeMessage")}</p>
          <Icon
            type="heart"
            theme="twoTone"
            twoToneColor="#eb2f96"
            style={{ fontSize: "50px" }}
          />
          <p>{t("tourPrompt.message")}</p>
        </div>
      </Modal>
    </div>
  );
};

export default TourGuide;
