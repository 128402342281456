import React from "react";
import { Collapse, Typography } from "antd";
import { useTranslation } from "react-i18next";

const { Panel } = Collapse;

const FAQ = () => {
  const { t } = useTranslation();
  const { Title } = Typography;
  const { Paragraph } = Typography;

  const faqData = [
    {
      question: t("FAQ.question1"),
      answer: t("FAQ.answer1"),
    },
    {
      question: t("FAQ.question2"),
      answer: t("FAQ.answer2"),
    },
    {
      question: t("FAQ.question3"),
      answer: t("FAQ.answer3"),
    },
    {
      question: t("FAQ.question4"),
      answer: t("FAQ.answer4"),
    },
    {
      question: t("FAQ.question5"),
      answer: t("FAQ.answer5"),
    },
    {
      question: t("FAQ.question6"),
      answer: t("FAQ.answer6"),
    },
    {
      question: t("FAQ.question7"),
      answer: t("FAQ.answer7"),
    },
    {
      question: t("FAQ.question8"),
      answer: t("FAQ.answer8"),
    },
  ];

  return (
    <div className="faq-container">
      <Title level={2}>{t("FAQ.title")}</Title>

      <Collapse accordion style={{ width: "80%" }}>
        {faqData.map((item, index) => (
          <Panel header={item.question} key={index + 1}>
            <p>{item.answer}</p>
            {index === faqData.length - 1 ? <Paragraph copyable>0657685961</Paragraph> : ""}
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default FAQ;
