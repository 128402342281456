import React, { useState, useEffect } from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

const InstallButton = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallButton, setShowInstallButton] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      // Save the event for triggering the manual prompt later
      setDeferredPrompt(e);
      setShowInstallButton(true); // Show the install button
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt(); // Trigger the saved install prompt
      const choiceResult = await deferredPrompt.userChoice;
      if (choiceResult.outcome === "accepted") {
        console.log("User accepted the A2HS prompt");
      } else {
        console.log("User dismissed the A2HS prompt");
      }
      setDeferredPrompt(null); // Clear the prompt after use
      setShowInstallButton(false); // Hide the button if the user interacts with it
    }
  };

  return (
    <div style={{ color: "#555" }}>
      {showInstallButton && (
        <Button
          shape="round"
          icon="download"
          size="large"
          type="danger"
          onClick={handleInstallClick}
        >
          {t("general.install")}
        </Button>
      )}
    </div>
  );
};

export default InstallButton;
