import React from "react";
import Slider from "react-slick";
import { Typography } from 'antd';
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import InstallButton from "../../utils/InstallButton/InstallButton";
function CustomCarousel({ externalImageUrls }) {
  const { t } = useTranslation();

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    swipeToSlide: true,
    autoplay: false,
    speed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div style={{  width:"80%" }}>
      <div style={{textAlign:"center", margin:"20px", color:"#555"}}>
        <h2>{t('prelaunch.carouselTitle')} </h2>
        <InstallButton />
      </div>
      <Slider {...settings}>
        {externalImageUrls.map((item) => {
          return (
            <div key={uuidv4} style={{margin:"10px", display: "flex", justifyContent:"center"}}>
              <img style={{ width: "150px", height:"200px" }} src={item} alt="product"/>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default CustomCarousel;
