import { useEffect, useState } from 'react';

const useWindowResize = () => {
  const breakpoints = {
    desktop: 1024,
    tablet: 768,
    mobile: 576,
  };

  const [device, setDevice] = useState('');

  useEffect(() => {
    const handleWindowResize = () => {
      const windowWidth = window.innerWidth;
      let deviceName = '';

      if (windowWidth >= breakpoints.desktop) {
        deviceName = 'desktop';
      } else if (windowWidth >= breakpoints.tablet) {
        deviceName = 'tablet';
      } else if (windowWidth >= breakpoints.mobile) {
        deviceName = 'mobile';
      } else {
        deviceName = 'mobile'; // Assign 'mobile' for window widths less than 576
      }

      setDevice(deviceName);
    };

    handleWindowResize(); // Initial device detection

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return device;
};

export default useWindowResize;

