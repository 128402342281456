import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "antd";

const ContactUs = () => {
  const { t } = useTranslation();
  const { Paragraph } = Typography;
  return (
    <div style={{ margin: "20px" }}>
      <h1>{t("contact.title")}</h1>
      <p>{t("contact.description")}</p>
      <Paragraph copyable={{ text: "talentmakes.socials@gmail.com" }}>
        {t("contact.email")}
      </Paragraph>
      <Paragraph copyable={{ text: "+213657685961" }}>
        {t("contact.phone")}
      </Paragraph>
    </div>
  );
};

export default ContactUs;
