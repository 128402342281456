import React, { useEffect } from "react";
import { Divider, Alert, Button } from "antd";
import { useTranslation } from "react-i18next";
import frontShot from "./assets/front-shot.jpg";
import sideAngleShot from "./assets/side-angle-shot.jpg";
import topDownShot from "./assets/top-down-shot.jpg";
import closeUpShot from "./assets/close-up-shot.jpg";
import contextShot from "./assets/context-shot.jpg";
import { useState } from "react";
import axios from "axios";

const ProductImgTutorial = (props) => {
  const { t } = useTranslation();
  const [acknowledged, setAcknowledged] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleAcknowledge = async () => {
    setAcknowledged(true);
    setLoading(true);
    try {
      await axios.post("/api/users/updateShowImgTutoStatus", {
        showTuto: false,
      });
      if (props.onUpdateShowTutoStatus) {
        props.onUpdateShowTutoStatus();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error updating showTuto status:", error);
    }
  };

  return (
    <div className="tutorial-container">
      <div className="tutorial-header">
        <h1>{t("howToTakePics.title")}</h1>
      </div>
      <Divider>
        <h1>{t("howToTakePics.mainTitle")}</h1>
      </Divider>
      <div className="video-container">
        <h2>{t("howToTakePics.videoTitle")}</h2>
        <div className="iframe-container">
          <iframe
            src="https://www.youtube.com/embed/lJhc15cyeTQ"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>

      <h2>{t("howToTakePics.overViewTitle")}</h2>

      <p>{t("howToTakePics.overView")}</p>
      <ol>
        <li>
          <strong> {t("howToTakePics.frontShotTitle")}</strong>{" "}
          {t("howToTakePics.frontShotExplain")}
          <br />
          <strong>{t("howToTakePics.focus")}</strong>{" "}
          {t("howToTakePics.frontShotFocus")}
          <br />
          <strong>{t("howToTakePics.tip")}</strong>{" "}
          {t("howToTakePics.frontShotTip")}
        </li>

        <li>
          <strong> {t("howToTakePics.sideAngleShotTitle")}</strong>{" "}
          {t("howToTakePics.sideAngleShotExplain")}
          <br />
          <strong>{t("howToTakePics.focus")}</strong>{" "}
          {t("howToTakePics.sideAngleShotFocus")}
          <br />
          <strong>{t("howToTakePics.tip")}</strong>{" "}
          {t("howToTakePics.sideAngleShotTip")}
        </li>

        <li>
          <strong> {t("howToTakePics.topDownShotTitle")}</strong>{" "}
          {t("howToTakePics.topDownShotExplain")}
          <br />
          <strong>{t("howToTakePics.focus")}</strong>{" "}
          {t("howToTakePics.topDownShotFocus")}
          <br />
          <strong>{t("howToTakePics.tip")}</strong>{" "}
          {t("howToTakePics.topDownShotTip")}
        </li>

        <li>
          <strong> {t("howToTakePics.closeUpShotTitle")}</strong>{" "}
          {t("howToTakePics.closeUpShotExplain")}
          <br />
          <strong>{t("howToTakePics.focus")}</strong>{" "}
          {t("howToTakePics.closeUpShotFocus")}
          <br />
          <strong>{t("howToTakePics.tip")}</strong>{" "}
          {t("howToTakePics.closeUpShotTip")}
        </li>

        <li>
          <strong> {t("howToTakePics.contextShotTitle")}</strong>{" "}
          {t("howToTakePics.contextShotExplain")}
          <br />
          <strong>{t("howToTakePics.focus")}</strong>{" "}
          {t("howToTakePics.contextShotFocus")}
          <br />
          <strong>{t("howToTakePics.tip")}</strong>{" "}
          {t("howToTakePics.contextShotTip")}
        </li>
      </ol>

      <h2>{t("howToTakePics.additionalTipsTitle")}</h2>
      <ul>
        <li>
          <strong>{t("howToTakePics.additionalTips.lightingTitle")}</strong>{" "}
          {t("howToTakePics.additionalTips.lightingExplain")}
          <br />
          <strong>{t("howToTakePics.focus")}</strong>{" "}
          {t("howToTakePics.additionalTips.lightingFocus")}
          <br />
          <strong>{t("howToTakePics.tip")}</strong>{" "}
          {t("howToTakePics.additionalTips.lightingTip")}
        </li>
        <li>
          <strong>{t("howToTakePics.additionalTips.backgroundTitle")}</strong>{" "}
          {t("howToTakePics.additionalTips.backgroundExplain")}
          <br />
          <strong>{t("howToTakePics.focus")}</strong>{" "}
          {t("howToTakePics.additionalTips.backgroundFocus")}
          <br />
          <strong>{t("howToTakePics.tip")}</strong>{" "}
          {t("howToTakePics.additionalTips.backgroundTip")}
        </li>
        <li>
          <strong>{t("howToTakePics.additionalTips.anglesTitle")}</strong>{" "}
          {t("howToTakePics.additionalTips.anglesExplain")}
          <br />
          <strong>{t("howToTakePics.focus")}</strong>{" "}
          {t("howToTakePics.additionalTips.anglesFocus")}
          <br />
          <strong>{t("howToTakePics.tip")}</strong>{" "}
          {t("howToTakePics.additionalTips.anglesTip")}
        </li>
        <li>
          <strong>{t("howToTakePics.additionalTips.editingTitle")}</strong>{" "}
          {t("howToTakePics.additionalTips.editingExplain")}
          <br />
          <strong>{t("howToTakePics.focus")}</strong>{" "}
          {t("howToTakePics.additionalTips.editingFocus")}
          <br />
          <strong>{t("howToTakePics.tip")}</strong>{" "}
          {t("howToTakePics.additionalTips.editingTip")}
        </li>
      </ul>
      <p>{t("howToTakePics.outro")}</p>
      <div className="example-images">
        <h2>{t("howToTakePics.example")}</h2>
        <div className="example-image">
          <img src={frontShot} alt={t("howToTakePics.frontShotTitle")} />
          <p>{t("howToTakePics.frontShotTitle")}</p>
        </div>
        <div className="example-image">
          <img
            src={sideAngleShot}
            alt={t("howToTakePics.sideAngleShotTitle")}
          />
          <p>{t("howToTakePics.sideAngleShotTitle")}</p>
        </div>
        <div className="example-image">
          <img src={topDownShot} alt={t("howToTakePics.topDownShotTitle")} />
          <p>{t("howToTakePics.topDownShotTitle")}</p>
        </div>
        <div className="example-image">
          <img src={closeUpShot} alt={t("howToTakePics.closeUpShotTitle")} />
          <p>{t("howToTakePics.closeUpShotTitle")}</p>
        </div>
        <div className="example-image">
          <img src={contextShot} alt={t("howToTakePics.contextShotTitle")} />
          <p>{t("howToTakePics.contextShotTitle")}</p>
        </div>
      </div>
      <Divider>
        <h1>{t("howToTakePics.warningTitle")}</h1>
      </Divider>
      <div className="warning-text">
        <p>{t("howToTakePics.warningPart1")}</p>
        <p>{t("howToTakePics.warningPart2")}</p>
        <p>{t("howToTakePics.warningPart3")}</p>
        <p>{t("howToTakePics.warningPart4")}</p>
        <p>{t("howToTakePics.warningPart5")}</p>
      </div>

      <div className="product-description-guide">
        <Divider>
          <h1>{t("productDescriptionGuide.title")}</h1>
        </Divider>
        <p>
          <strong>{t("productDescriptionGuide.productNameTitle")}:</strong>{" "}
          {t("productDescriptionGuide.productNameDescription")}
        </p>

        <p>
          <strong>{t("productDescriptionGuide.productOverviewTitle")}:</strong>{" "}
          {t("productDescriptionGuide.productOverviewDescription")}
        </p>

        <p>
          <strong>{t("productDescriptionGuide.keyFeaturesTitle")}:</strong>{" "}
          {t("productDescriptionGuide.keyFeaturesDescription")}
        </p>

        <p>
          <strong>{t("productDescriptionGuide.benefitsTitle")}:</strong>{" "}
          {t("productDescriptionGuide.benefitsDescription")}
        </p>

        <p>
          <strong>
            {t("productDescriptionGuide.usageInstructionsTitle")}:
          </strong>{" "}
          {t("productDescriptionGuide.usageInstructionsDescription")}
        </p>

        <h2>{t("productDescriptionGuide.exampleTitle")}</h2>

        <p>
          <strong>{t("productDescriptionGuide.productNameTitle")}:</strong>{" "}
          {t("productDescriptionGuide.exampleProductName")}
        </p>

        <p>
          <strong>{t("productDescriptionGuide.productOverviewTitle")}:</strong>{" "}
          {t("productDescriptionGuide.exampleProductOverview")}
        </p>

        <p>
          <strong>{t("productDescriptionGuide.keyFeaturesTitle")}:</strong>
        </p>
        <ul>
          {t("productDescriptionGuide.exampleKeyFeatures", {
            returnObjects: true,
          }).map((feature, index) => (
            <li key={index}>{feature}</li>
          ))}
        </ul>

        <p>
          <strong>{t("productDescriptionGuide.benefitsTitle")}:</strong>
        </p>
        <ul>
          {t("productDescriptionGuide.exampleBenefits", {
            returnObjects: true,
          }).map((benefit, index) => (
            <li key={index}>{benefit}</li>
          ))}
        </ul>

        <p>
          <strong>
            {t("productDescriptionGuide.usageInstructionsTitle")}:
          </strong>
        </p>
        <ul>
          {t("productDescriptionGuide.exampleUsageInstructions", {
            returnObjects: true,
          }).map((instruction, index) => (
            <li key={index}>{instruction}</li>
          ))}
        </ul>
      </div>
      <Alert description={t("howToTakePics.warning")} type="warning" showIcon />
      {props.showAgreeButton && (
        <div className="confirmation-checkbox">
          <p>
            {t("howToTakePics.agreeDescription", {
              defaultMessage:
                "By clicking 'Agree', you confirm that you have read the tutorial and will follow the guidelines for taking product pictures.",
            })}
          </p>
          <Button
            type="danger"
            onClick={handleAcknowledge}
            disabled={acknowledged}
            loading={loading}
          >
            {t("howToTakePics.agreeButton", { defaultMessage: "Agree" })}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ProductImgTutorial;
