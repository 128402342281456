import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const TermsUpdateBanner = () => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const dismissed = localStorage.getItem("bannerDismissed");
    if (!dismissed) {
      setVisible(true);
    }
  }, []);

  const handleDismiss = () => {
    setVisible(false);
    localStorage.setItem("bannerDismissed", "true");
  };

  if (!visible) return null;

  return (
    <div
      style={{
        backgroundColor: "#ffeeba",
        padding: "15px",
        textAlign: "center",
        position: "fixed",
        top: 60,
        width: "100%",
        zIndex: 1000,
      }}
    >
      🚨 <strong>{t("termsUpdateBanner.updatedTerms")}</strong>
      {t("termsUpdateBanner.effectiveDate")}
      👉{" "}
      <a
        href="/terms-and-conditions"
        style={{ color: "#007bff", textDecoration: "underline" }}
      >
        {t("termsUpdateBanner.readUpdatedTerms")}
      </a>
      <button
        onClick={handleDismiss}
        style={{
          marginLeft: "10px",
          background: "none",
          border: "none",
          color: "#007bff",
          textDecoration: "underline",
          cursor: "pointer",
        }}
      >
        {t("termsUpdateBanner.dismiss")}
      </button>
    </div>
  );
};

export default TermsUpdateBanner;
