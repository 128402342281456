import React from "react";
import { Card, Typography } from "antd";
import shopAnim from "./animationShop.gif";
import deliveryAnim from "./animationDelivery.gif";
import paymentAnim from "./animationMoney.gif";
import animationShopping from "./animationShopping.gif";
import { useTranslation } from "react-i18next";

const ExplanationCards = () => {

  const { Title } = Typography;
    const { t } = useTranslation();
const cards = [
    {
        title: t("howItWorks.title1"),
        description: t("howItWorks.description1"),
        src: shopAnim,
    },
    {
        title: t("howItWorks.title2"),
        description: t("howItWorks.description2"),
        src: deliveryAnim,
    },
    {
        title: t("howItWorks.title3"),
        description: t("howItWorks.description3"),
        src: paymentAnim,
    },
    {
        title: t("howItWorks.title4"),
        description: t("howItWorks.description4"),
        src: animationShopping,
    },
];

return (
    <div className="explanation-card-container">
        <Title level={2}>{t("howItWorks.title")}</Title>
        <p>{t("howItWorks.description")}</p>
        <div className="card-wrapper">
            {cards.map((card, index) => (
                <Card
                    key={index}
                    hoverable
                    style={{ width: 240, margin: "5px 10px", height: 400 }}
                    cover={<img alt={`Card ${index + 1}`} src={card.src} style={{height: "238.167px"}}/>}
                >
                    <Card.Meta title={card.title} description={card.description} />
                </Card>
            ))}
        </div>
    </div>
);
};

export default ExplanationCards;
