export const LOGIN_USER = 'login_user';
export const REGISTER_USER = 'register_user';
export const AUTH_USER = 'auth_user';
export const LOGOUT_USER = 'logout_user';
export const ADD_TO_CART_USER = 'add_to_cart_user';
export const GET_CART_ITEM_USER = 'get_cart_item_user';
export const REMOVE_CART_ITEM_USER = 'remove_cart_item_user';
export const ON_SUCCESS_BUY_USER = 'on_success_buy_user';
export const ADD_TO_ORDER_USER = 'add_to_order_user';
export const ON_ORDER_SUCCESS = 'on_order_success';
export const GET_ORDERS_ITEMS = 'get_orders_items';
export const REQUEST_DELIVERY = 'request_delivery';
export const ON_DELIVERY_ORDER_SUCCESS = "on_delivery_order_success";
export const ON_ACCEPT_DELIVERY_SUCCESS = "on_accept_delivery_success";
export const ON_SEND_DELIVERY_FEEDBACK = "on_send_delivery_feedback";
export const APPLY_DISCOUNT = "apply_discount";
export const ADD_PRODUCER_PAYMENT_INFO = "add_producer_payment_info";