import React, { useState } from "react";
import { Icon } from "antd";
import { Typography, Button } from "antd";
import { useTranslation } from "react-i18next";

const ContactUsBtn = () => {
  const [showChat, setShowChat] = useState(false);
  const phoneNumber = "213657685961"; // Replace with your phone number
  const { Paragraph } = Typography;
  const { t } = useTranslation();

  const handleClick = () => {
    setShowChat(true);
  };

  const handleCloseChat = () => {
    setShowChat(false);
  };

  return (
    <div>
      <button className="contact-us-button" onClick={handleClick}>
        <Icon type="message" />
      </button>

      {showChat && (
        <div className="chat-container">
          <div className="chat-header">
            <span className="chat-close" onClick={handleCloseChat}>
              &times;
            </span>
            <p>{t("contactUs.title")}</p>
          </div>
          <div className="chat-body">
            <div className="message-container">
              <p>{t("contactUs.message")}</p>
              <div class="arrow right"></div>
            </div>
            <div className="message-container">
              {t("contactUs.message2")}
              <a
                href={`https://wa.me/${phoneNumber}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button type="primary">WhatsApp</Button>
              </a>
              <div class="arrow right"></div>
            </div>
            <div className="message-container">
              {t("contactUs.message3")}:
              <Paragraph copyable>0657685961</Paragraph>
              <div class="arrow right"></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactUsBtn;
