import React from "react";
import CustomCarousel from "./CustomCarousel";
import TypingEffect from "./TypingEffect";
import { Button, Badge, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import WhatsAppButton from "../../utils/ContactUsBtn";
import ExplanationCards from "./ExplanationCards";
import FAQ from "./FAQ";
import AppDemo from "./AppDemo";

const PreLaunch = () => {
  const { t } = useTranslation();
  const { Text } = Typography;





  const arr1 = [
    t("prelaunch.arr1msg1"),
    t("prelaunch.arr1msg2"),
    t("prelaunch.arr1msg3"),
    t("prelaunch.arr1msg4"),
  ];

  const arr2 = [
    t("prelaunch.arr2msg1"),
    t("prelaunch.arr2msg2"),
    t("prelaunch.arr2msg3"),
    t("prelaunch.arr2msg4"),
    t("prelaunch.arr2msg5"),
    t("prelaunch.arr2msg6"),
    t("prelaunch.arr2msg7"),
  ];

  const arr3 = [
    t("prelaunch.arr3msg1"),
    t("prelaunch.arr3msg2"),
    t("prelaunch.arr3msg3"),
    t("prelaunch.arr3msg4"),
  ];

  const arr4 = [t("prelaunch.arr4msg1")];
  return (
    <div className="prelauch-productshow">
     
{/*       <div>
        <div className="message-container">
          <Badge status="success" text={t("prelaunch.on")} />
          <div className="message-content">
            <div>
              {t("prelaunch.join")}{" "}
              <Link to="/register">
                <Button type="primary">{t("login.registerNw")}</Button>
              </Link>{" "}
              {t("prelaunch.share")}{" "}
              <Text mark copyable>
                www.talentmakes.com
              </Text>{" "}
            </div>
            <p>{t("prelaunch.whoCanJoin")}</p>
          </div>
          <div className="arrow right"></div>
        </div>
        <TypingEffect texts={arr1} arrayType="1" />
        <TypingEffect texts={arr2} arrayType="2" />
        <TypingEffect texts={arr3} arrayType="3" />
        <TypingEffect texts={arr4} arrayType="4" />
      </div> */}
      <ExplanationCards />
      <FAQ />
      <WhatsAppButton />
      <AppDemo />
    </div>
  );
};

export default PreLaunch;
