import React, { useState } from "react";
import LeftMenu from "./Sections/LeftMenu";
import RightMenu from "./Sections/RightMenu";
import { Drawer, Button, Icon } from "antd";
import useWindowResize from "../../utils/BreakPoints";
import { useSelector } from "react-redux";
import "./Sections/Navbar.css";

function NavBar() {
  const [visible, setVisible] = useState(false);
  const deviceType = useWindowResize();
  const user = useSelector((state) => state.user);

  const rightMenuContainerStyle = {
    display:
      user.userData && !user.userData.isAuth && deviceType !== "desktop"
        ? "none"
        : "block",
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <nav
      className="menu"
      style={{ position: "fixed", zIndex: 5, width: "100%" }}
    >
      <Button
        className="menu__mobile-button"
        type="primary"
        onClick={showDrawer}
      >
        <Icon type="menu" />
      </Button>
      <div className="menu__logo">
        <a href="/">talentmakes</a>
      </div>
      <div className="menu__container">
        <div className="menu_left">
          <LeftMenu mode="horizontal" />
        </div>
        <div style={rightMenuContainerStyle} className="menu_rigth">
          <RightMenu mode="horizontal" />
        </div>

        <Drawer
          title="talentmakes"
          placement="left"
          className="menu_drawer"
          closable={false}
          onClose={onClose}
          visible={visible}
        >
          <LeftMenu mode="inline" />
          <RightMenu mode="inline" />
        </Drawer>
      </div>
    </nav>
  );
}

export default NavBar;
