import React, { Component } from "react";
import { Result } from "antd";
import { withTranslation } from "react-i18next";


class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
    };
  }

  render() {
    if (this.state.hasError) {
      return (
        <Result
          className="center1"
          style={{ width: "100%", height: "100%", top: "30%" }}
          status="500"
          title={this.props.t("errorBoundary.msgTitle")}
          subTitle={this.props.t("errorBoundary.msgBody")}
         
        />
      );
    }

    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
